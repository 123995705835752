<template>
    <div v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.3)" class="rule-go-editor">
        <div class="op-panel">
            <ButtonLeftPanel @click="toggleViewVisibleByIndex('codeEditor')" />
            <ButtonRightPanel @click="toggleViewVisibleByIndex('chat')" />
        </div>
        <SplitPanelGroup ref="splitPanelGroupRef" @adjust-width="onAdjustWidth">
            <draggable v-model="widgets" class="content" @end="onDragEnd" item=".split-panel" handle=".title">
                <SplitPanel v-for="(widget, index) of widgets" :showHandler="index < widgets.length - 1" :key="widget.id">
                    <component
                        :is="widget.component"
                        v-bind="widget.props"
                        v-model="model.contentText"
                        @apply="onApply(widget.id, $event)"
                        @close="onClose(widget.id)" />
                </SplitPanel>
            </draggable>
        </SplitPanelGroup>
    </div>
</template>

<script>
import SplitPanelGroup from '../SplitPanelGroup.vue';
import SplitPanel from '../SplitPanel.vue';
import draggable from 'vuedraggable';
import ButtonLeftPanel from './components/ButtonLeftPanel.vue';
import ButtonRightPanel from './components/ButtonRightPanel.vue';
import RuleGoChat from './components/RuleGoChat.vue';
import RuleGoCodeEditor from './components/RuleGoCodeEditor.vue';
import RuleGoDiagram from './components/RuleGoDiagram.vue';
import * as api from '@/api';
import { assignCoordinates } from '@/utils/rulegoTools';

export default {
    components: {
        SplitPanelGroup,
        SplitPanel,
        draggable,
        RuleGoChat,
        RuleGoCodeEditor,
        RuleGoDiagram,
        ButtonLeftPanel,
        ButtonRightPanel
    },
    props: {
        id: {
            type: [String, Object],
            default: null
        }
    },
    data() {
        return {
            loading: false,
            showCodeEditor: true,
            showChat: true,
            model: {
                contentText: ''
            },
            widgets: [],
            defaultWidgets: [
                { id: 'diagram', component: 'RuleGoDiagram', props: { class: 'item', ref: 'diagram' } },
                { id: 'codeEditor', component: 'RuleGoCodeEditor', props: { id: 'id' } },
                { id: 'chat', component: 'RuleGoChat', props: { id: 'id', chainId: "id" } }
            ],
            defaultJson: JSON.stringify({
                "ruleChain": {
                    "id": "chain_call_rest_api",
                    "name": "测试规则链",
                    "debugMode": false,
                    "root": true,
                    "additionalInfo": {
                        "layoutX": "280",
                        "layoutY": "280"
                    }
                },
                "metadata": {
                    "endpoints": [],
                    "nodes": [
                        {
                            "id": "s1",
                            "additionalInfo": {
                                "description": "ddd",
                                "layoutX": 450,
                                "layoutY": 240
                            },
                            "type": "jsFilter",
                            "name": "过滤",
                            "debugMode": true,
                            "configuration": {
                                "jsScript": "return msg!='bb';"
                            }
                        },
                        {
                            "id": "s2",
                            "additionalInfo": {
                                "description": "",
                                "layoutX": 670,
                                "layoutY": 280
                            },
                            "type": "jsTransform",
                            "name": "转换",
                            "debugMode": true,
                            "configuration": {
                                "jsScript": "metadata['test']='test02';\nmetadata['index']=52;\nmsgType='TEST_MSG_TYPE2';\nmsg['aa']=66;\nreturn {'msg':msg,'metadata':metadata,'msgType':msgType};"
                            }
                        },
                        {
                            "id": "s3",
                            "additionalInfo": {
                                "description": "",
                                "layoutX": 930,
                                "layoutY": 190
                            },
                            "type": "restApiCall",
                            "name": "推送数据",
                            "debugMode": true,
                            "configuration": {
                                "headers": {
                                    "Content-Type": "application/json"
                                },
                                "maxParallelRequestsCount": 200,
                                "requestMethod": "POST",
                                "restEndpointUrlPattern": "http://127.0.0.1:9090/api/v1/webhook/test"
                            }
                        },
                        {
                            "id": "node_5",
                            "additionalInfo": {
                                "description": "",
                                "layoutX": 920,
                                "layoutY": 370
                            },
                            "type": "log",
                            "name": "记录错误日志",
                            "debugMode": false,
                            "configuration": {
                                "jsScript": "return 'Incoming message:\\\\n' + JSON.stringify(msg) +\n  '\\\\nIncoming metadata:\\\\n' + JSON.stringify(metadata);"
                            }
                        }
                    ],
                    "connections": [
                        {
                            "fromId": "s1",
                            "toId": "s2",
                            "type": "True"
                        },
                        {
                            "fromId": "s2",
                            "toId": "s3",
                            "type": "Success"
                        },
                        {
                            "fromId": "s2",
                            "toId": "node_5",
                            "type": "Failure"
                        }
                    ]
                }
            }, null, 2)
        }
    },
    computed: {
        widthStyle() {
            if (this.showCodeEditor && this.showChat) {
                return 'width: 33.33%;'
            }
            else if (this.showCodeEditor || this.showChat) {
                return 'width: 50%;'
            }
            else {
                return 'width: 100%;'
            }
        }
    },
    methods: {
        onAdjustWidth(widget) {
            if (widget.$slots.default[0].tag.includes('RuleGoDiagram')) {
                this.$refs.diagram.translateCenter()
            }
        },
        async fetchData() {
            try {
                this.loading = true;
                const o = await api.getRuleById(this.id)
                this.model.contentText = JSON.stringify(o, null, 2)
            }
            finally {
                this.loading = false;
            }
        },
        toggleViewVisibleByIndex(id) {
            const me = this;
            let index = me.widgets.findIndex(widget => widget.id === id);
            if (index > -1) {
                me.widgets.splice(index, 1);
            }
            else {
                let widget = me.defaultWidgets.find(widget => widget.id === id);
                if (widget) {
                    me.widgets.push(widget);
                }
            }
        },
        onClose(id) {
            let index = this.widgets.findIndex(widget => widget.id === id);
            if (index > -1) {
                this.widgets.splice(index, 1);
            }
        },
        onApplyJson(value) {
            try {
                value = assignCoordinates(value);
            }
            finally {
                this.model.contentText = value;
            }
        },
        onApply(widgetId, value) {
            const me = this;
            switch (widgetId) {
                case 'diagram':
                    break;
                case 'codeEditor':
                    me.submit();
                    break;
                case 'chat':
                    me.onApplyJson(value);
                    break;
            }
        },
        async submit() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            try {
                const o = JSON.parse(this.model.contentText)
                const chainId = o.ruleChain.id;
                await api.addOrUpdateRule(o, chainId)
                this.$message({
                    message: 'Save success',
                    type: 'success'
                });
            }
            catch (error) {
                this.$message({
                    message: 'Save failed',
                    type: 'error'
                });
            }
            this.loading = false;
        },
        async init() {
            this.widgets = this.defaultWidgets.slice();
            if (this.id) {
                await this.fetchData();
            }
            else {
                this.model.contentText = this.defaultJson;
            }
        },
        onDragEnd(event) {
            this.$refs.splitPanelGroupRef.replaceChildrenIndex(event.oldIndex, event.newIndex);
        },
    },
    mounted() {
        this.init();
    }
}
</script>
<style>
.rule-go-editor {
    position: relative;
    overflow: visible;
    flex: 1 1 0;
    display: flex;
    flex-direction: row;
}

.rule-go-editor .split-panel-group>.content {
    display: flex;
    flex-direction: row;
    flex: 1 1 0;
    height: 100%;
    /* Ensure the grid-layout has a height of 100% */
}

.rule-go-editor .split-panel-group>.content .item {
    overflow: hidden;
}

.rule-go-editor>.op-panel {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 0;
    right: 0;
    transform: translateY(-100%);
}
</style>
